import { library, dom } from '@fortawesome/fontawesome-svg-core';

// load the icons you want to use
import { faInfoCircle, faRss } from '@fortawesome/pro-solid-svg-icons';

// add them to the library
library.add(faInfoCircle,faRss);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();
